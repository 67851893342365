import { useProfileStore } from '~~/stores/profile.store'
import { useSubscriptionStore } from '~~/stores/subscription.store'

export default defineNuxtRouteMiddleware((to, from) => {
  console.log('check if is pro user')
  const { isProUser } = storeToRefs(useProfileStore())

  useSubscriptionStore().$subscribe(() => {
    if (isProUser.value) return navigateTo('/dashboard')
  })

  if (isProUser.value) return navigateTo('/dashboard')
})
